<template>
  <div class="container">
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-card>
        <v-toolbar dark color="primary">
          
          <v-toolbar-title>
            Modificar Datos
           
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
             <v-alert
          color="purple"
          dark
          dismissible
          outlined
        >Los datos obligatorios son el documento, el e-mail, el apellido, el nombre y la contraseña, pero recomendamos cargar la mayor cantidad de datos posibles</v-alert>
          <v-row dense>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="paciente.dni"
                prepend-icon="mdi-account"
                
                label="D.N.I"
                dense
                required
                @keypress="onlyNumber"
               
                :rules="requiredRules"
                maxlength="9"
              ></v-text-field>
            </v-col>
              <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="paciente.mail"
                prepend-icon="mdi-email"
                label="E-mail"
                :rules="emailRules"
                dense
                maxlength="80"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="paciente.apellidos"
                prepend-icon="mdi-account"
                label="Apellidos"
                maxlength="50"
                :rules="requiredRules"
                @blur="upper"
                dense
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="paciente.nombres"
                prepend-icon="mdi-account"
                label="Nombres"
                maxlength="50"
                :rules="requiredRules"
                @blur="upperNombres"
                dense
                required
              ></v-text-field>
            </v-col>
                         
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="paciente.direccion"
                prepend-icon="mdi-map-marker"
                label="Direccion"
                maxlength="80"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="paciente.localidad"
                prepend-icon="mdi-map-marker"
                label="Localidad"
                maxlength="80"
                dense
              ></v-text-field>
            </v-col>
             <v-col cols="12" sm="3" md="3">
              <v-text-field
                v-model="paciente.fechaNac"
                :value="paciente.fechaNac"
                label="Fecha de Nacimiento"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-select
                :items="sexo"
                v-model="paciente.sexo"
                item-text="paciente.sexo"
                item-value="paciente.sexo"
                label="Sexo"
                dense
                prepend-icon="mdi-account"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-text-field
                v-model="paciente.celular"
                prepend-icon="mdi-cellphone"
                label="Celular"
                hint="Ingrese el celular con la característica, sin 0, sin 15 y sin guiones. Ej: 1135036549"
                maxlength="50"
                dense
                :rules="celularRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-text-field
                v-model="paciente.telefono"
                prepend-icon="mdi-phone"
                label="Telefono"
                maxlength="50"
                dense
              ></v-text-field>
            </v-col>
          
           
          
          </v-row>
        </v-card-text>
        <v-card-actions>
          
          <v-spacer></v-spacer>
          <v-btn color="success" :disabled="!valid" @click.stop="modificarDatos">Modificar</v-btn>

          <v-btn color="error" @click.stop="salir">Salir</v-btn>
        </v-card-actions>
       
      </v-card>
    </v-form>
  </div>
</template>

<script>
import axios from "../../axios-auth.js";
import router from "../../router/index.js";
export default {
  data: (vm) => ({
    horaTurno: null,
    paciente: {
      dni: null,
      apellidos: null,
      nombres: null,
      fechaNac: null,
      sexo: null,
      direccion: null,
      localidad: null,
      mail: null,
      telefono: null,
      celular: null,
      
    },
   
    sexo: ["FEMENINO", "MASCULINO", "OTRO"],
    valid: false,
    lazy: false,
    requiredRules: [(v) => !!v || "Dato obligatorio"],
    emailRules: [
      (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "E-mail inválido",
        (v) => !!v || "Dato obligatorio"
    ],
     passwordRules: [
      (v) => (v && v.length > 5)|| "Mínimo 6 caracteres",
        (v) => !!v || "Dato obligatorio"
    ],
    celularRules: [
      (v) => !v || v.length == 10 || "Debe tener 10 caracteres"
    ]
  }),
  mounted(){
    this.recuperarDatosUsuario();
  },
  methods: {
    recuperarDatosUsuario(){
      const idUsuariopaciente = this.$store.state.idUsuarioPaciente;
      let self = this;

      axios
        .get("/Usuario/BuscarDatosUsuarioPaciente?", {
          params: {
            idUsuariopaciente: idUsuariopaciente
          }
        })
        .then(response => {
   
          self.paciente.dni = response.data.nrodocumento;
          self.paciente.apellidos = response.data.apellidos;
          self.paciente.nombres = response.data.nombres;
          if (response.data.fechanacimiento == "0001-01-01T00:00:00")
            self.paciente.fechaNac = "";
          else
            self.paciente.fechaNac = response.data.fechanacimiento.substring(
              0,
              10
            );
          self.paciente.sexo = response.data.sexo;
          self.paciente.localidad = response.data.localidad;
          self.paciente.direccion = response.data.direccion;
          self.paciente.celular = response.data.celular;
          self.paciente.mail = response.data.email;
          self.paciente.telefono = response.data.telefono;          
        })
        .catch(error => {
          
        });
    },
    modificarDatos() {
      this.$swal({
        title: "Modificar Datos Usuario",
        text: "¿Desea modificar los datos asociados a su usuario?",
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          const datosUsuario = {
            idUsuarioPaciente: this.$store.state.idUsuarioPaciente,
            nrodocumento: this.paciente.dni,
            apellidos: this.paciente.apellidos,
            nombres: this.paciente.nombres,
            fechanacimiento: this.paciente.fechaNac,
            sexo: this.paciente.sexo,
            direccion: this.paciente.direccion,
            localidad: this.paciente.localidad,
            celular: this.paciente.celular,
            telefono: this.paciente.telefono,
            email: this.paciente.mail
          };
          
          let self = this;
          axios
            .post("/Usuario/ModificarDatosUsuario", datosUsuario)
            .then((response) => {
                    if(response.data == "dni")
                    {
                      self.showAlert({
                      icon: "error",
                      title: "El D.N.I. ingresado ya se encuentra registrado como usuario.",
                      vm: self,
                    });
                      
                    }
                    else{
                    self.showAlert({
                      icon: "success",
                      title: "Datos modificados exitosamente.",
                      vm: self,
                    });
                    
                    }
                    
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "Los datos no pudieron ser modificados.",
                vm: this,
              });
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
   
    onlyNumber($event) {
    
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    upper() {
      if (this.paciente.apellidos != null)
        this.paciente.apellidos = this.paciente.apellidos
          .toString()
          .toUpperCase();
    },
    upperNombres() {
      if (this.paciente.nombres != null)
        this.paciente.nombres = this.paciente.nombres.toString().toUpperCase();
    },
    
    vaciarModels() {
      this.paciente.apellidos = "";
      this.paciente.nombres = "";
      this.paciente.direccion = "";
      this.paciente.localidad = "";
      this.paciente.celular = "";
      this.paciente.telefono = "";
      this.paciente.mail = "";
      this.paciente.fechaNac = null;
      this.paciente.sexo = null;
      this.paciente.dni = "";
      
     
    },

    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 3800,
      });
    },
        
    salir(){
        router.push('/Home')
    }
  },
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  created() {
    this.vaciarModels();
   
  },
};
</script>