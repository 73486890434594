<template>
  <div class="ma-0 pa-0">
    <v-card tile>
      <v-card-text class="mt-0">
        <v-row class="ml-2">
          <h4>Elegir Dia y Horario del Turno</h4>
        </v-row>
        <v-row class="ml-2">
          <h4>Profesional: {{ nombreProfesional }}</h4>
        </v-row>
      </v-card-text>
      <v-toolbar flat class="pt-4">
        <v-row class="mb-2">
          <v-btn
            class="ml-4 mr-2"
            max-width="40px"
            min-width="40px"
            @click="retrocederSemana"
            tile
            color="blue"
            dark
            :disabled="date == fechaMinima"
          >
            <v-icon large>mdi-arrow-left-bold</v-icon>
          </v-btn>

          <v-menu
            v-if="!sinTurnos"
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="computedDateFormatted"
                label="Semana del"
                @blur="date = date"
                readonly
                v-on="on"
                class="shrink"
                style="width: 88px"
              ></v-text-field>
            </template>

            <v-date-picker
              v-model="date"
              @input="menu2 = false"
              locale="es-ar"
              :first-day-of-week="1"
              @change="armarTurnos"
              :allowed-dates="allowedDates"
              :min="fechaMinima"
              :max="fechaMaxima"
            ></v-date-picker>
          </v-menu>

          <v-btn
            class="ml-2"
            max-width="40px"
            min-width="40px"
            @click="avanzarSemana"
            tile
            color="blue"
            dark
            :disabled="date >= fechaMaxima"
          >
            <v-icon large>mdi-arrow-right-bold</v-icon>
          </v-btn>
          <!-- <v-col>
     <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">Ver reseña de íconos y colores</v-btn>
      </template>
          </v-col>-->
        </v-row>
      </v-toolbar>
      <v-row v-if="loadingTurnos">
        <v-spacer></v-spacer>
        <div class="loader mr-2 float"></div>
        <h4 class="mt-2">Reservando turno</h4>
        <v-spacer></v-spacer>
      </v-row>
    </v-card>
    <v-data-table
      v-if="!sinTurnos"
      :headers="headers"
      :items="listaTurnos"
      hide-default-header
      :hide-default-footer="true"
      :loading="isLoading"
      item-key="id"
      locale="es-ar"
      class="elevation-1 mytable mt-2"
      :disable-sort="true"
      :items-per-page="500"
    >
      <template v-slot:item.diaSemana="{ item }">
        <v-chip
          :color="
            getColor(
              item.hora,
              item.paciente,
              item.entreTurno,
              item.estadoTurno
            )
          "
          dark
        >
          <v-avatar class="mr-2" color="#541402" size="12">
            <span class="white--text">{{
              item.diaSemana.toString().substring(0, 2)
            }}</span>
          </v-avatar>
          {{ item.fechaTurnoString }}</v-chip
        >
      </template>

      <template v-slot:item.hora="{ item }">
        <v-chip
          :color="
            getColor(
              item.hora,
              item.paciente,
              item.entreTurno,
              item.estadoTurno
            )
          "
          dark
          >{{ item.hora }}</v-chip
        >
      </template>

      <template v-slot:item.paciente="{ item }">
        <v-chip
          :color="
            getColorPaciente(
              item.hora,
              item.paciente,
              item.entreTurno,
              item.estadoTurno
            )
          "
          dark
        >
          {{ item.paciente }}
          <v-avatar
            class="ml-2"
            v-if="item.cantIntervalos > 1"
            color="#541402"
            size="32"
          >
            <span class="white--text">{{ item.cantIntervalos }}</span>
          </v-avatar>
        </v-chip>
      </template>
      <!-- <template v-slot:item.intervalos="{ item }">
          <v-avatar v-if="item.cantIntervalos>0" :color="getColor(item.hora, item.paciente, item.entreTurno, item.estadoTurno)" size="32"><span class="white--text headline">{{item.cantIntervalos}}</span></v-avatar>
        </template>-->
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              rounded
              color="green"
              dark
              :disabled="loadingTurnos"
              @click.stop="guardarTurno(item)"
            >
              <v-icon class="mr-2" dark left> mdi-calendar </v-icon>Reservar
              Turno
            </v-btn>
          </template>
          <span>Reservar Turno</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-alert
      color="blue"
      dark
      outlined
      v-if="listaTurnos.length == 0"
      class="mb-0 mt-2"
      >{{
        sinTurnos
          ? "Profesional sin turnos disponibles"
          : "No hay turnos disponibles esta semana."
      }}</v-alert
    >
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click.stop="volver()">Volver</v-btn>
    </v-card-actions>
  </div>
</template>
<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

<script>
import axios from "../../axios-auth.js";
import router from "../../router/index.js";
import moment from "moment";
export default {
  name: "GestionTurnos",
  props: [
    "idInstitucion",
    "idProfesional",
    "lista",
    "fechaMinima",
    "fechaMaxima",
    "idObraSocial",
    "nombreProfesional",
    "loading",
    "nroAfiliado",
    "initialDate",
  ],
  data: (vm) => ({
    valid: true,
    headers: [
      {
        text: "Fecha",
        align: "start",
        filterable: false,
        value: "diaSemana",
      },
      {
        text: "Hora",
        align: "start",
        filterable: false,
        value: "hora",
      },

      { text: "Acciones", value: "actions", sortable: false },
    ],
    headersLength: {
      type: Number,
    },

    defaultItem: {
      hora: "",
      paciente: "",
    },
    date: null,
    dateFormatted: null,
    menu: false,
    modal: false,
    menu2: false,
    diaSemana: null,
    on: null,
    horaTurno: "",
    loadingTurnos: false,
  }),
  watch: {
    dialog(val) {
      val || this.close();
    },
    initialDate: {
      immediate: true,
      handler(val) {
        this.date = val;
        this.dateFormatted = moment(val).format("DD/MM/YYYY");
      },
    },
  },
  computed: {
    sinTurnos() {
      return this.date == this.fechaMinima && this.date >= this.fechaMaxima;
    },
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Turno" : "Editar Turno";
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    listaTurnos: {
      get() {
        return this.lista;
      },
      set(value) {
        if (value != []) {
          this.$emit("buscarTurnos", this.date);
        }
      },
    },
    isLoading: {
      get() {
        return this.loading;
      },
    },
  },
  mounted() {
    this.setToday();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    setToday() {
      var hoy = new Date().getDay();
      if (hoy == 0) hoy = 6;
      else hoy -= 1;
      let dias = 1000 * 60 * 60 * 24 * hoy;

      let resta = new Date().getTime() - dias;
      this.date = this.toISOLocal(new Date(resta));
      this.dateFormatted = moment(resta).format("DD/MM/YYYY");
    },

    volver() {
      this.setToday();
      this.$emit("goToStep3");
    },

    guardarTurno(item, val) {
      this.loadingTurnos = true;
      this.$swal({
        title: "Guardar Turno",
        text: "¿Desea registrar el turno?",
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          const datosTurno = {
            idInstitucion: this.idInstitucion,
            idUsuarioPaciente: this.$store.state.idUsuarioPaciente,
            fechaTurnoString: item.fechaTurnoString,
            horaInicioString: item.hora,
            duracionTurno: item.tiempoTurno,
            idObraSocial: this.idObraSocial,
            idProfesional: this.idProfesional,
            nroAfiliado: this.nroAfiliado,
          };

          let self = this;
          axios
            .post("/PortalPaciente/GuardarTurno", datosTurno)
            .then((response) => {
              if (response.data == "Reservado") {
                self.showAlert({
                  icon: "error",
                  title:
                    "Disculpe, el turno acaba de ser tomado. Reserve otro por favor",
                  vm: self,
                });
              } else {
                if (response.data && response.data != "Reservado") {
                  self
                    .$swal({
                      title:
                        "Turno reservado. Se enviará un mail a su cuenta de correo electrónico. Revise por favor su correo no deseado o spam.",
                      icon: "success",
                      type: "success",
                      showCancelButton: true,
                      background: "#ececec",
                      confirmButtonText: "Pagar Consulta",
                      cancelButtonText: "No",
                      showCloseButton: true,
                      showLoaderOnConfirm: true,
                    })
                    .then((result) => {
                      if (result.value) {
                        window.open(response.data, "_blank");
                      }
                    });
                } else {
                  self.showAlert({
                    icon: "success",
                    title:
                      "Turno reservado. Se enviará un mail a su cuenta de correo electrónico. Revise por favor su correo no deseado o spam.",
                    vm: self,
                  });
                }
                self.$emit("turnoRegistrado");
              }
              self.loadingTurnos = false;
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "El turno no pudo ser reservado",
                vm: this,
              });
              self.loadingTurnos = false;
            });
        } else {
          this.loadingTurnos = false;
        }
      });
    },
    salir() {
      router.push("/Home");
    },

    retrocederSemana() {
      let dias = 1000 * 60 * 60 * 24 * 6;
      let resta = new Date(this.date).getTime() - dias;
      this.date = this.toISOLocal(new Date(resta));
      this.dateFormatted = moment(resta).format("DD/MM/YYYY");
      this.armarTurnos();
    },
    avanzarSemana() {
      let dias = 1000 * 60 * 60 * 24 * 8;
      let resta = new Date(this.date).getTime() + dias;
      this.date = this.toISOLocal(new Date(resta));
      this.dateFormatted = moment(resta).format("DD/MM/YYYY");
      this.armarTurnos();
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },

    allowedDates: (val) => new Date(val).getDay() === 0,
    armarTurnos() {
      this.listaTurnos = 1;
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    getColor(hora, paciente, entreTurno, estadoTurno) {
      if (hora === "Feriado" || hora === "Cancelación") return "red";
      else {
        if (paciente === "Disponible") return "green";
        else {
          if (estadoTurno === 1) {
            if (entreTurno === 1) return "grey";
            else return "blue";
          } else {
            if (estadoTurno === 2) return "orange";
            else return "purple";
          }
        }
      }
    },
    getColorFecha(diaSemana) {
      if (diaSemana.toString().substring(0, 2) == "LU") return "#aeaeae";
      if (diaSemana.toString().substring(0, 2) == "MA") return "#868686";
      if (diaSemana.toString().substring(0, 2) == "MI") return "#626262";
      if (diaSemana.toString().substring(0, 2) == "JU") return "#454545";
      if (diaSemana.toString().substring(0, 2) == "VI") return "#333333";
      if (diaSemana.toString().substring(0, 2) == "SA") return "#1f1f1f";
      if (diaSemana.toString().substring(0, 2) == "DO") return "#000000";
    },
    getColorPaciente(hora, paciente, entreTurno, estadoTurno) {
      if (hora === "Feriado" || hora === "Cancelación") return "red";
      else {
        if (paciente === "Disponible") return "green";
        else {
          if (estadoTurno === 1) {
            if (entreTurno === 1) return "grey";
            else return "blue";
          } else {
            if (estadoTurno === 2) return "orange";
            else return "purple";
          }
        }
      }
    },

    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 2000,
      });
    },

    calcularHora(hora) {
      var division = hora.split(":");
      var hoy = new Date();
      var fecha = new Date(
        hoy.getYear(),
        hoy.getMonth(),
        hoy.getDay(),
        division[0],
        parseInt(division[1] - 1)
      );
      var minutos = fecha.getMinutes().toString();
      if (minutos.length == 1) minutos = "0" + minutos;
      var horaNueva = fecha.getHours() + ":" + minutos;
      return horaNueva;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}-`;
    },
    toISOLocal(d) {
      var z = (n) => ("0" + n).slice(-2);
      var zz = (n) => ("00" + n).slice(-3);
      var off = d.getTimezoneOffset();
      var sign = off < 0 ? "+" : "-";
      off = Math.abs(off);
      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate()) +
        "T" +
        z(d.getHours()) +
        ":" +
        z(d.getMinutes()) +
        ":" +
        z(d.getSeconds()) +
        "." +
        zz(d.getMilliseconds()) +
        "Z"
      )
        .toString()
        .substr(0, 10);
    },
    turnoRegistrado() {
      this.armarTurnos();
      setTimeout(this.cerrarVentana, 600);
    },
    cerrarVentana() {
      this.showNuevoTurno = false;
    },

    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 3500,
      });
    },
  },
};
</script>
