var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":_vm.maxWidth,"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.show = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Ver Informe")])],1),_c('div',{staticClass:"divClass"},[(_vm.extension == '.pdf')?_c('iframe',{attrs:{"src":_vm.ruta,"width":"100%","height":_vm.height}}):_vm._e(),(
        _vm.extension == '.jpg' ||
          _vm.extension == '.jpeg' ||
          _vm.extension == '.png' ||
          _vm.extension == '.PNG' ||
          _vm.extension == '.JPEG' ||
          _vm.extension == '.JPG'
      )?_c('div',{directives:[{name:"viewer",rawName:"v-viewer.rebuild",value:({ inline: true }),expression:"{ inline: true }",modifiers:{"rebuild":true}}],staticClass:"images",staticStyle:{"height":"100%"}},_vm._l((_vm.images),function(src){return _c('img',{key:src,staticClass:"image",attrs:{"src":src}})}),0):_vm._e(),(_vm.extension == '.doc' || _vm.extension == '.docx')?_c('iframe',{attrs:{"src":_vm.encodedUrl,"width":"100%","height":"100%","frameborder":"0"}},[_vm._v("This is an embedded "),_c('a',{attrs:{"href":"http://office.com"}},[_vm._v("Microsoft Office")]),_vm._v(" document, powered by "),_c('a',{attrs:{"target":"_blank","href":"http://office.com/webapps"}},[_vm._v("Office Online")]),_vm._v(".")]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }