<template>
  <div>
    <v-card-text class="pt-0 mt-0">
      <v-chip class="mt-4" label color="transparent" text-color="black">
        <h3>Seleccionar Profesional</h3>
      </v-chip>
    </v-card-text>
    <div class="cointainer">
      <v-row class="mx-4 profesionales-row">
        <div
          v-for="item in listaProfesionales"
          :key="item.idProfesional"
          class="col-sm-4 col-md-3 card"
          style="border: 0.1px solid grey; margin: 10px;"
          @click="seleccionar(item)"
        >
          <div class="panel panel-success">
            <div class="panel-heading text-center">
              <v-img
                class="img center mb-3"
                max-height="150px"
                max-width="150px"
                alt="Sin Foto"
                :aspect-ratio="1"
                :src="item.foto"
                position="top"
              ></v-img>

              <h2 class="panel-title">
                {{ item.nombre }}
              </h2>
            </div>
            <div class="panel-body text-center mt-1">
              <h4>Especialidad: {{ item.especialidad }}</h4>
              <h4
                class="mt-1"
                style="color: red !important"
                v-if="item.observaciones != '' && item.observaciones != null"
              >
                Observaciones: {{ item.observaciones }}
              </h4>
              <h4 v-else><br /></h4>
              <!-- <v-btn small class="success mt-2" @click="seleccionar(item)"
                >Seleccionar</v-btn
              > -->
            </div>
          </div>
        </div>
      </v-row>
    </div>
  </div>
</template>
<style scoped>
.center {
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.img {
  border: 0.5px solid black;
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border: 0px !important;
  cursor: pointer;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  background-color: rgb(210, 223, 228);
  transform: scale(1.05);
}
.profesionales-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* padding-left: 8rem;
  padding-right: 8rem; */
}
</style>
<script>
export default {
  props: ["listaProfesionales"],
  data: (vm) => ({}),
  methods: {
    buscarProfesionalesInstitucion() {
      const institucionData = {
        idInstitucion: this.idInstitucion,
      };
      let self = this;
      axios
        .get("/PortalPaciente/GetProfInstDispWeb?", {
          params: {
            idInstitucion: institucionData.idInstitucion,
          },
        })
        .then(function(response) {
          self.listaProfesionales = response.data;
        })
        .catch(function(error) {
          self.listaProfesionales = [];
        });
    },
    seleccionar(item) {
      this.$emit("profesionalSeleccionado", item);
    },
  },
};
</script>
