<template>
  <div>
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Nuevo Turno</v-toolbar-title>
      </v-toolbar>
    </v-card>
    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step
          :complete="e1 > 1"
          step="1"
          v-if="this.$store.state.idInstitucion === 0"
          >Buscar Institución</v-stepper-step
        >

        <v-divider v-if="this.$store.state.idInstitucion === 0"></v-divider>
        <v-stepper-step :complete="e1 > 2" :step="stepOs"
          >Seleccionar
          {{ $store.state.esEmpresa ? "Sede" : "Obra Social" }}</v-stepper-step
        >

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 3" :step="stepProf"
          >Seleccionar Profesional</v-stepper-step
        >

        <v-divider></v-divider>

        <v-stepper-step :step="stepTurno">Elegir Turno</v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content
          step="1"
          class="pa-2"
          v-if="this.$store.state.idInstitucion === 0"
        >
          <BuscarInstFiltros
            @institucionSeleccionada="institucionSeleccionada($event)"
          ></BuscarInstFiltros>
        </v-stepper-content>

        <v-stepper-content :step="stepOs" class="pa-2">
          <SeleccionarOS
            :obrasSociales="obrasSociales"
            :idInstitucion="$store.state.idInstitucion || idInstitucion"
            @obraSocialSeleccionada="obraSocialSeleccionada($event)"
          >
          </SeleccionarOS>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click.stop="goToInstOrHome()">Volver</v-btn>
          </v-card-actions>
          <v-divider></v-divider>
        </v-stepper-content>
        <v-stepper-content :step="stepProf" class="pa-2">
          <BuscarProfesional
            :listaProfesionales="listaProfesionales"
            @profesionalSeleccionado="profesionalSeleccionado($event)"
          ></BuscarProfesional>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click.stop="goToOs()">Volver</v-btn>
          </v-card-actions>
          <v-divider></v-divider>
        </v-stepper-content>

        <v-stepper-content :step="stepTurno" class="mx-0 px-0 pt-1">
          <GestionTurnosSemanaWide
            v-if="this.$store.state.vista == 0"
            :idProfesional="idProfesional"
            :idInstitucion="idInstitucion"
            :listaLunes="listaTurnosLunes"
            :listaMartes="listaTurnosMartes"
            :listaMiercoles="listaTurnosMiercoles"
            :listaJueves="listaTurnosJueves"
            :listaViernes="listaTurnosViernes"
            :listaSabado="listaTurnosSabado"
            :listaDomingo="listaTurnosDomingo"
            :headerL="headerLunes"
            :headerM="headerMartes"
            :headerMi="headerMiercoles"
            :headerJ="headerJueves"
            :headerV="headerViernes"
            :headerS="headerSabado"
            :headerD="headerDomingo"
            :fechaMinima="initialDate"
            :fechaMaxima="fechaMaxima"
            :idObraSocial="idObraSocial"
            :nombreProfesional="nombreProfesional"
            :loading="isLoading"
            :nroAfiliado="nroAfiliado"
            :initialDate="initialDate"
            @turnoRegistrado="turnoRegistrado"
            @buscarTurnos="buscarTurnos($event)"
            @goToStep3="goToProf()"
          ></GestionTurnosSemanaWide>
          <GestionTurnosSemana
            v-if="this.$store.state.vista == 1"
            :idProfesional="idProfesional"
            :idInstitucion="idInstitucion"
            :lista="listaTurnos"
            :fechaMinima="initialDate"
            :fechaMaxima="fechaMaxima"
            :idObraSocial="idObraSocial"
            :nombreProfesional="nombreProfesional"
            :loading="isLoading"
            :nroAfiliado="nroAfiliado"
            :initialDate="initialDate"
            @turnoRegistrado="turnoRegistrado"
            @buscarTurnos="buscarTurnos($event)"
            @goToStep3="goToProf()"
          ></GestionTurnosSemana>

          <v-divider></v-divider>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <!-- <v-card>
      <v-card-actions>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              href="https://api.whatsapp.com/send?phone=5493547461089&text=Hola%21%20Necesito%20ayuda%20con%20el%20portal%20del%20paciente"
              class="mx-2"
              small
              fab
              dark
              color="teal"
              v-on="on"
              v-bind="attrs"
            >
              <v-icon color="white">mdi-whatsapp</v-icon>
            </v-btn>
          </template>
<span>Necesito ayuda</span>
</v-tooltip>
<v-spacer></v-spacer>
<v-btn color="error" @click.stop="salir">Salir</v-btn>
</v-card-actions>
</v-card> -->
  </div>
</template>
<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
</style>

<script>
import axios from "../../axios-auth.js";
import router from "../../router/index.js";
import BuscarInstFiltros from "../Turnos/BuscaInstFiltros.vue";
import BuscarProfesional from "../Turnos/BuscarProfesional.vue";
import GestionTurnosSemanaWide from "../Turnos/GestionTurnosSemanaWide.vue";
import GestionTurnosSemana from "../Turnos/GestionTurnosSemana.vue";
import SeleccionarOS from "../Turnos/SeleccionarOS.vue";
import moment from "moment";

export default {
  name: "GestionTurnos",

  data: (vm) => ({
    e1: null,
    idInstitucion: null,
    nombreProfesional: null,
    idObraSocial: null,
    nroAfiliado: "",
    listaProfesionales: [],
    idProfesional: null,
    date: null,
    initialDate: null,
    dateFormatted: null,
    listaTurnos: [],
    listaTurnosLunes: [],
    listaTurnosMartes: [],
    listaTurnosMiercoles: [],
    listaTurnosJueves: [],
    listaTurnosViernes: [],
    listaTurnosSabado: [],
    listaTurnosDomingo: [],
    obrasSociales: [],
    obraSocial: [],
    headerLunes: [
      {
        text: "Hora",
        align: "center",
        filterable: false,
        value: "hora",
      },
    ],
    headerMartes: [
      {
        text: "Hora",
        align: "center",
        filterable: false,
        value: "hora",
      },
    ],
    headerMiercoles: [
      {
        text: "Hora",
        align: "center",
        filterable: false,
        value: "hora",
      },
    ],
    headerJueves: [
      {
        text: "Hora",
        align: "center",
        filterable: false,
        value: "hora",
      },
    ],
    headerViernes: [
      {
        text: "Hora",
        align: "center",
        filterable: false,
        value: "hora",
      },
    ],
    headerSabado: [
      {
        text: "Hora",
        align: "center",
        filterable: false,
        value: "hora",
      },
    ],
    headerDomingo: [
      {
        text: "Hora",
        align: "center",
        filterable: false,
        value: "hora",
      },
    ],
    headersLength: {
      type: Number,
    },
    fechaMinima: null,
    fechaMaxima: null,
    isLoading: false,
  }),
  watch: {
    dialog(val) {
      val || this.close();
    },
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  computed: {
    stepOs() {
      return this.$store.state.idInstitucion ? 1 : 2;
    },
    stepProf() {
      return this.$store.state.idInstitucion ? 2 : 3;
    },
    stepTurno() {
      return this.$store.state.idInstitucion ? 3 : 4;
    },
  },
  async created() {
    if (this.$store.state.idInstitucion) {
      await this.institucionSeleccionada(this.$store.state.idInstitucion);
    }
  },
  methods: {
    goToInstOrHome() {
      if (!this.$store.state.idInstitucion) {
        this.e1 = 1;
      } else {
        router.push("/Home");
      }
    },
    goToOs() {
      this.e1 = this.$store.state.idInstitucion ? 1 : 2;
    },
    goToProf() {
      this.initialDate = this.toISOLocal(new Date());
      this.e1 = this.$store.state.idInstitucion ? 2 : 3;
    },
    turnoRegistrado() {
      router.push("/Home");
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}-`;
    },
    toISOLocal(d) {
      var z = (n) => ("0" + n).slice(-2);
      var zz = (n) => ("00" + n).slice(-3);
      var off = d.getTimezoneOffset();
      var sign = off < 0 ? "+" : "-";
      off = Math.abs(off);
      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate()) +
        "T" +
        z(d.getHours()) +
        ":" +
        z(d.getMinutes()) +
        ":" +
        z(d.getSeconds()) +
        "." +
        zz(d.getMilliseconds()) +
        "Z"
      )
        .toString()
        .substr(0, 10);
    },
    salir() {
      this.initialDate = this.toISOLocal(new Date());
      router.push("/Home");
    },
    async institucionSeleccionada(idInstitucion) {
      this.idInstitucion = idInstitucion;
      await this.buscarObrasSociales(idInstitucion);
      this.e1 = this.$store.state.idInstitucion ? 1 : 2;
    },
    obraSocialSeleccionada(datosAfiliatorios) {
      this.idObraSocial = datosAfiliatorios.obraSocial;
      this.nroAfiliado = datosAfiliatorios.nroAfiliado;
      this.buscarProfesionales();
      this.e1 = this.$store.state.idInstitucion ? 2 : 3;
    },
    async profesionalSeleccionado(item) {
      this.idProfesional = item.idProfesional;
      this.idInstitucion = this.idInstitucion;
      this.nombreProfesional = item.nombre;

      var hoy = new Date().getDay();
      if (hoy == 0) hoy = 6;
      else hoy -= 1;
      let dias1 = 1000 * 60 * 60 * 24 * hoy;

      let resta1 = new Date().getTime() - dias1;
      let lunes = this.toISOLocal(new Date(resta1));

      let dias = 1000 * 60 * 60 * 24 * 152;
      let resta = new Date(lunes).getTime() + dias;
      this.fechaMaxima = this.toISOLocal(new Date(resta));

      await this.buscarTurnosInicial("");
      this.e1 = this.$store.state.idInstitucion ? 3 : 4;
    },
    async buscarObrasSociales(idInstitucion) {
      const osData = {
        idInstitucion: idInstitucion,
      };
      let self = this;
      const { data: obrasSociales } = await axios.get(
        "/ObraSocial/GetObraSocialActivasDeInstWeb?",
        {
          params: {
            nombre: osData.nombre,
            idInstitucion: osData.idInstitucion,
          },
        }
      );
      if (obrasSociales) {
        this.obrasSociales = obrasSociales;
      } else {
        this.obrasSociales = [];
      }
    },
    buscarProfesionales() {
      const institucionData = {
        idInstitucion: this.idInstitucion,
        idObraSocial: this.idObraSocial,
      };
      let self = this;
      axios
        .get("/PortalPaciente/GetProfInstDispWeb?", {
          params: {
            idInstitucion: institucionData.idInstitucion,
            idObraSocial: institucionData.idObraSocial,
          },
        })
        .then(function (response) {
          self.listaProfesionales = response.data;
        })
        .catch(function (error) {
          self.listaProfesionales = [];
        });
    },
    buscarTurnos(item) {
      this.isLoading = true;
      var hoy = new Date().getDay();
      if (hoy == 0) hoy = 6;
      else hoy -= 1;
      let dias = 1000 * 60 * 60 * 24 * hoy;

      let resta = new Date().getTime() - dias;
      let date;
      if (item == "" || item == null) date = this.toISOLocal(new Date(resta));
      else date = item;

      this.dateFormatted = this.formatDate(this.toISOLocal(new Date(resta)));

      if (this.$store.state.vista == 0) this.armarTurnos(date);
      else this.armarTurnosCel(date);
      this.isLoading = false;
    },

    async buscarTurnosInicial(item) {
      var hoy = new Date().getDay();
      if (hoy == 0) hoy = 6;
      else hoy -= 1;
      let dias = 1000 * 60 * 60 * 24 * hoy;

      let resta = new Date().getTime() - dias;
      let date;
      if (item == "" || item == null) date = this.toISOLocal(new Date(resta));
      else date = item;

      this.dateFormatted = this.formatDate(this.toISOLocal(new Date(resta)));

      if (this.$store.state.vista == 0) await this.armarTurnosInicial(date);
      else await this.armarTurnosCelInicial(date);
    },

    armarTurnos(date) {
      const turnosData = {
        idProfesional: this.idProfesional,
        fecha: date,
        idInstitucion: this.idInstitucion,
        idObraSocial: this.idObraSocial,
      };
      //this.listaTurnos = []
      let self = this;
      this.$loading(true);
      axios
        .get("/Turnos/ArmarTurnosWidePortal?", {
          params: {
            idProfesional: turnosData.idProfesional,
            fecha: turnosData.fecha,
            idInstitucion: turnosData.idInstitucion,
            idObraSocial: turnosData.idObraSocial,
            idUsuarioPaciente: this.$store.state.idUsuarioPaciente,
          },
        })
        .then(function (response) {
          if (response.data != null && response.data != "") {
            self.listaTurnosLunes = response.data[0];
            self.listaTurnosMartes = response.data[1];
            self.listaTurnosMiercoles = response.data[2];
            self.listaTurnosJueves = response.data[3];
            self.listaTurnosViernes = response.data[4];
            self.listaTurnosSabado = response.data[5];
            self.listaTurnosDomingo = response.data[6];
          } else {
            self.listaTurnosLunes = [];
            self.listaTurnosMartes = [];
            self.listaTurnosMiercoles = [];
            self.listaTurnosJueves = [];
            self.listaTurnosViernes = [];
            self.listaTurnosSabado = [];
            self.listaTurnosDomingo = [];
          }
          self.$loading(false);
        })
        .catch(function (error) {
          self.listaTurnosLunes = [];
          self.listaTurnosMartes = [];
          self.listaTurnosMiercoles = [];
          self.listaTurnosJueves = [];
          self.listaTurnosViernes = [];
          self.listaTurnosSabado = [];
          self.listaTurnosDomingo = [];
          self.$loading(false);
        });
      this.armarHeaders(date);
    },
    armarTurnosCel(date) {
      const turnosData = {
        idProfesional: this.idProfesional,
        fecha: date,
        idInstitucion: this.idInstitucion,
        idObraSocial: this.idObraSocial,
      };
      //this.listaTurnos = []
      let self = this;
      this.$loading(true);
      axios
        .get("/Turnos/ArmarTurnosSemanaPortal?", {
          params: {
            idProfesional: turnosData.idProfesional,
            fecha: turnosData.fecha,
            idInstitucion: turnosData.idInstitucion,
            idObraSocial: turnosData.idObraSocial,
            idUsuarioPaciente: this.$store.state.idUsuarioPaciente,
          },
        })
        .then(function (response) {
          if (response.data != null && response.data != "")
            self.listaTurnos = response.data;
          else self.listaTurnos = [];
          self.$loading(false);
        })
        .catch(function (error) {
          self.listaTurnos = [];
          self.$loading(false);
        });
    },
    armarTurnosInicial(date) {
      const turnosData = {
        idProfesional: this.idProfesional,
        fecha: date,
        idInstitucion: this.idInstitucion,
        idObraSocial: this.idObraSocial,
      };
      //this.listaTurnos = []
      let self = this;
      this.$loading(true);
      axios
        .get("/Turnos/ArmarTurnosWidePortal?", {
          params: {
            idProfesional: turnosData.idProfesional,
            fecha: turnosData.fecha,
            idInstitucion: turnosData.idInstitucion,
            idObraSocial: turnosData.idObraSocial,
            idUsuarioPaciente: this.$store.state.idUsuarioPaciente,
          },
        })
        .then(function (response) {
          if (response.data != null && response.data != "") {
            self.listaTurnosLunes = response.data[0];
            self.listaTurnosMartes = response.data[1];
            self.listaTurnosMiercoles = response.data[2];
            self.listaTurnosJueves = response.data[3];
            self.listaTurnosViernes = response.data[4];
            self.listaTurnosSabado = response.data[5];
            self.listaTurnosDomingo = response.data[6];
            if (
              !self.listaTurnosLunes.length &&
              !self.listaTurnosMartes.length &&
              !self.listaTurnosMiercoles.length &&
              !self.listaTurnosJueves.length &&
              !self.listaTurnosViernes.length &&
              !self.listaTurnosSabado.length &&
              !self.listaTurnosDomingo.length
            ) {
              let dias = 1000 * 60 * 60 * 24 * 8;
              let resta = new Date(date).getTime() + dias;
              let dateNew = self.toISOLocal(new Date(resta));
              if (moment(dateNew).isSameOrBefore(self.fechaMaxima)) {
                self.armarTurnosInicial(dateNew);
              } else {
                self.initialDate = dateNew;
                self.$loading(false);
              }
            } else {
              self.initialDate = date;
              self.$loading(false);
            }
          } else {
            self.listaTurnosLunes = [];
            self.listaTurnosMartes = [];
            self.listaTurnosMiercoles = [];
            self.listaTurnosJueves = [];
            self.listaTurnosViernes = [];
            self.listaTurnosSabado = [];
            self.listaTurnosDomingo = [];
            self.$loading(false);
          }
        })
        .catch(function (error) {
          self.listaTurnosLunes = [];
          self.listaTurnosMartes = [];
          self.listaTurnosMiercoles = [];
          self.listaTurnosJueves = [];
          self.listaTurnosViernes = [];
          self.listaTurnosSabado = [];
          self.listaTurnosDomingo = [];
          this.$loading(false);
        });
      this.armarHeaders(date);
    },
    armarTurnosCelInicial(date) {
      const turnosData = {
        idProfesional: this.idProfesional,
        fecha: date,
        idInstitucion: this.idInstitucion,
        idObraSocial: this.idObraSocial,
      };
      //this.listaTurnos = []
      let self = this;
      this.$loading(true);
      axios
        .get("/Turnos/ArmarTurnosSemanaPortal?", {
          params: {
            idProfesional: turnosData.idProfesional,
            fecha: turnosData.fecha,
            idInstitucion: turnosData.idInstitucion,
            idObraSocial: turnosData.idObraSocial,
            idUsuarioPaciente: this.$store.state.idUsuarioPaciente,
          },
        })
        .then(function (response) {
          if (response.data != null && response.data != "") {
            self.listaTurnos = response.data;
            self.initialDate = date;
            self.$loading(false);
          } else {
            self.listaTurnos = [];
            let dias = 1000 * 60 * 60 * 24 * 8;
            let resta = new Date(date).getTime() + dias;
            let dateNew = self.toISOLocal(new Date(resta));
            if (moment(dateNew).isSameOrBefore(self.fechaMaxima)) {
              self.armarTurnosCelInicial(dateNew);
            } else {
              self.$loading(false);
              self.initialDate = dateNew;
            }
          }
        })
        .catch(function (error) {
          self.listaTurnos = [];
          self.$loading(false);
        });
    },
    armarHeaders(date) {
      let dias = 1000 * 60 * 60 * 24;

      let lunes = new Date(date).getTime() + dias * 1;
      this.dateFormattedLunes = this.formatDate(
        this.toISOLocal(new Date(lunes))
      );

      let martes = new Date(date).getTime() + dias * 2;
      this.dateFormattedMartes = this.formatDate(
        this.toISOLocal(new Date(martes))
      );

      let miercoles = new Date(date).getTime() + dias * 3;
      this.dateFormattedMiercoles = this.formatDate(
        this.toISOLocal(new Date(miercoles))
      );

      let jueves = new Date(date).getTime() + dias * 4;
      this.dateFormattedJueves = this.formatDate(
        this.toISOLocal(new Date(jueves))
      );

      let viernes = new Date(date).getTime() + dias * 5;
      this.dateFormattedViernes = this.formatDate(
        this.toISOLocal(new Date(viernes))
      );

      let sabado = new Date(date).getTime() + dias * 6;
      this.dateFormattedSabado = this.formatDate(
        this.toISOLocal(new Date(sabado))
      );
      let domingo = new Date(date).getTime() + dias * 7;
      this.dateFormattedDomingo = this.formatDate(
        this.toISOLocal(new Date(domingo))
      );

      this.headerLunes = [];
      this.headerLunes.push({
        text: "Lunes " + this.dateFormattedLunes,
        align: "center",
        filterable: false,
        value: "hora",
      });
      this.headerMartes = [];
      this.headerMartes.push({
        text: "Martes " + this.dateFormattedMartes,
        align: "center",
        filterable: false,
        value: "hora",
      });
      this.headerMiercoles = [];
      this.headerMiercoles.push({
        text: "Miércoles " + this.dateFormattedMiercoles,
        align: "center",
        filterable: false,
        value: "hora",
      });
      this.headerJueves = [];
      this.headerJueves.push({
        text: "Jueves " + this.dateFormattedJueves,
        align: "center",
        filterable: false,
        value: "hora",
      });
      this.headerViernes = [];
      this.headerViernes.push({
        text: "Viernes " + this.dateFormattedViernes,
        align: "center",
        filterable: false,
        value: "hora",
      });
      this.headerSabado = [];
      this.headerSabado.push({
        text: "Sábado " + this.dateFormattedSabado,
        align: "center",
        filterable: false,
        value: "hora",
      });
      this.headerDomingo = [];
      this.headerDomingo.push({
        text: "Domingo " + this.dateFormattedDomingo,
        align: "center",
        filterable: false,
        value: "hora",
      });
    },
  },
  components: {
    BuscarInstFiltros,
    BuscarProfesional,
    GestionTurnosSemanaWide,
    GestionTurnosSemana,
    SeleccionarOS,
  },
};
</script>
