<template>
  <div class="ma-0 pa-0">
    <v-card tile>
      <v-card-text class="mt-0">
        <v-chip class="mt-0" label color="transparent" text-color="black">
          <h3>
            Elegir Dia y Horario del Turno - Profesional:
            {{ nombreProfesional }}
          </h3>
        </v-chip>
      </v-card-text>
      <v-toolbar flat class="pt-2">
        <!-- <v-row>
          <h3>Profesional: PRIETO, ROBERTO DANIEL</h3>
        </v-row> -->

        <v-row>
          <v-btn
            class="ml-4 mr-2"
            max-width="40px"
            min-width="40px"
            @click="retrocederSemana"
            tile
            color="blue"
            dark
            :disabled="date == fechaMinima"
          >
            <v-icon large>mdi-arrow-left-bold</v-icon>
          </v-btn>

          <v-menu
            v-if="!sinTurnos"
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="dateFormatted"
                label="Semana del"
                @blur="date = date"
                readonly
                v-on="on"
                class="shrink"
                style="width: 88px"
              ></v-text-field>
            </template>

            <v-date-picker
              v-model="date"
              @input="menu2 = false"
              locale="es-ar"
              :first-day-of-week="1"
              @change="armarTurnos"
              :allowed-dates="allowedDates"
              :min="fechaMinima"
              :max="fechaMaxima"
            ></v-date-picker>
          </v-menu>

          <v-btn
            max-width="40px"
            min-width="40px"
            class="ml-2"
            @click="avanzarSemana"
            tile
            color="blue"
            dark
            :disabled="date >= fechaMaxima"
          >
            <v-icon large>mdi-arrow-right-bold</v-icon>
          </v-btn>
          <!-- <v-col>
     <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">Ver reseña de íconos y colores</v-btn>
      </template>
          </v-col>-->
          <v-spacer></v-spacer>
          <div v-if="loadingTurnos" class="loader mr-2"></div>
          <h4 v-if="loadingTurnos" class="mt-2">Reservando turno</h4>
          <v-spacer></v-spacer>
        </v-row>
      </v-toolbar>
    </v-card>
    <v-row>
      <v-col cols="2" class="ma-0 pa-0">
        <v-data-table
          v-if="!sinTurnos"
          :headers="headerLunes"
          :items="this.listaTurnosLunes"
          dense
          @click:row="despliegaMenu"
          :hide-default-footer="true"
          :loading="isLoading"
          locale="es-ar"
          item-key="id"
          class="row-pointer"
          :item-class="rowClasses"
          :disable-sort="true"
          :items-per-page="500"
        ></v-data-table>

        <v-menu
          v-model="showMenu"
          :position-x="x"
          :position-y="y"
          absolute
          offset-y
        >
          <v-list dense>
            <v-list-item
              v-for="(item, index) in itemsMenu"
              :key="index"
              @click="definirAccion(item)"
            >
              <v-list-item-icon class="mr-2 pr-2">
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col cols="2" class="ma-0 pa-0">
        <v-data-table
          v-if="!sinTurnos"
          :headers="headerMartes"
          :items="this.listaTurnosMartes"
          dense
          @click:row="despliegaMenu"
          :hide-default-footer="true"
          :loading="isLoading"
          locale="es-ar"
          item-key="id"
          class="row-pointer"
          :item-class="rowClasses"
          :disable-sort="true"
          :items-per-page="500"
        ></v-data-table>
      </v-col>
      <v-col cols="2" class="ma-0 pa-0">
        <v-data-table
          v-if="!sinTurnos"
          :headers="headerMiercoles"
          :items="this.listaTurnosMiercoles"
          dense
          @click:row="despliegaMenu"
          :hide-default-footer="true"
          :loading="isLoading"
          locale="es-ar"
          item-key="id"
          class="row-pointer"
          :item-class="rowClasses"
          :disable-sort="true"
          :items-per-page="500"
        >
          <!-- <template v-slot:item.intervalos="{ item }">
          <v-avatar v-if="item.cantIntervalos>0" :color="getColor(item.hora, item.paciente, item.entreTurno, item.estadoTurno)" size="32"><span class="white--text headline">{{item.cantIntervalos}}</span></v-avatar>
          </template>-->
        </v-data-table>
      </v-col>
      <v-col cols="2" class="ma-0 pa-0">
        <v-data-table
          v-if="!sinTurnos"
          :headers="headerJueves"
          :items="this.listaTurnosJueves"
          dense
          @click:row="despliegaMenu"
          :hide-default-footer="true"
          :loading="isLoading"
          locale="es-ar"
          item-key="id"
          class="row-pointer"
          :item-class="rowClasses"
          :disable-sort="true"
          :items-per-page="500"
        ></v-data-table>
      </v-col>
      <v-col cols="2" class="ma-0 pa-0">
        <v-data-table
          v-if="!sinTurnos"
          :headers="headerViernes"
          :items="this.listaTurnosViernes"
          dense
          @click:row="despliegaMenu"
          :hide-default-footer="true"
          :loading="isLoading"
          locale="es-ar"
          item-key="id"
          class="row-pointer"
          :item-class="rowClasses"
          :disable-sort="true"
          :items-per-page="500"
        >
          <!-- <template v-slot:item.intervalos="{ item }">
          <v-avatar v-if="item.cantIntervalos>0" :color="getColor(item.hora, item.paciente, item.entreTurno, item.estadoTurno)" size="32"><span class="white--text headline">{{item.cantIntervalos}}</span></v-avatar>
          </template>-->
        </v-data-table>
      </v-col>
      <v-col cols="2" class="ma-0 pa-0">
        <v-data-table
          v-if="!sinTurnos"
          :headers="headerSabado"
          :items="this.listaTurnosSabado"
          dense
          class="row-pointer"
          @click:row="despliegaMenu"
          :hide-default-footer="true"
          :loading="isLoading"
          locale="es-ar"
          item-key="id"
          :item-class="rowClasses"
          :disable-sort="true"
          :items-per-page="500"
        >
          <!-- <template v-slot:item.intervalos="{ item }">
          <v-avatar v-if="item.cantIntervalos>0" :color="getColor(item.hora, item.paciente, item.entreTurno, item.estadoTurno)" size="32"><span class="white--text headline">{{item.cantIntervalos}}</span></v-avatar>
          </template>-->
        </v-data-table>
      </v-col>
      <v-col cols="2" class="ma-0 pa-0">
        <v-data-table
          :headers="headerDomingo"
          :items="this.listaTurnosDomingo"
          dense
          v-if="listaTurnosDomingo.length > 0 && !sinTurnos"
          class="row-pointer"
          @click:row="despliegaMenu"
          :hide-default-footer="true"
          :loading="isLoading"
          locale="es-ar"
          item-key="id"
          :item-class="rowClasses"
          :disable-sort="true"
          :items-per-page="500"
        >
          <!-- <template v-slot:item.intervalos="{ item }">
          <v-avatar v-if="item.cantIntervalos>0" :color="getColor(item.hora, item.paciente, item.entreTurno, item.estadoTurno)" size="32"><span class="white--text headline">{{item.cantIntervalos}}</span></v-avatar>
          </template>-->
        </v-data-table>
      </v-col>
    </v-row>
    <v-alert
      color="blue"
      dark
      outlined
      v-if="
        listaTurnosLunes.length == 0 &&
        listaTurnosMartes.length == 0 &&
        listaTurnosMiercoles.length == 0 &&
        listaTurnosJueves.length == 0 &&
        listaTurnosViernes.length == 0 &&
        listaTurnosSabado.length == 0 &&
        listaTurnosDomingo.length == 0
      "
      class="mb-0 mt-2"
      >{{
        sinTurnos
          ? "Profesional sin turnos disponibles"
          : "No hay turnos disponibles esta semana."
      }}</v-alert
    >
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click.stop="volver()">Volver</v-btn>
    </v-card-actions>
  </div>
</template>
<style scoped>
.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

::v-deep .v-data-table__empty-wrapper {
  display: none;
}
::v-deep .v-data-table__wrapper {
  color: white;
  font-weight: bold;

  height: fit-content;
}
::v-deep .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 38px;
  font-size: 0.67rem;
  border: 0.5px solid;
}
::v-deep .v-data-table__wrapper > table > tbody > tr.dos > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr.dos > td {
  height: 76px;
  font-size: 0.67rem;
  border: 0.5px solid;
}
::v-deep .v-data-table__wrapper > table > tbody > tr.tres > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr.tres > td {
  height: 114px;
  font-size: 0.67rem;
  border: 0.5px solid;
}
::v-deep .v-data-table__wrapper > table > tbody > tr.cuatro > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr.cuatro > td {
  height: 152px;
  font-size: 0.67rem;
  border: 0.5px solid;
}
::v-deep .v-data-table__wrapper > table > tbody > tr.cinco > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr.cinco > td {
  height: 190px;
  font-size: 0.67rem;
  border: 0.5px solid;
}
::v-deep .v-data-table__wrapper > table > tbody > tr.seis > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr.seis > td {
  height: 228px;
  font-size: 0.67rem;
  border: 0.5px solid;
}
::v-deep .v-data-table__wrapper > table > tbody > tr.siete > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr.siete > td {
  height: 266px;
  font-size: 0.67rem;
  border: 0.5px solid;
}
::v-deep .v-data-table__wrapper > table > tbody > tr.ocho > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr.ocho > td {
  height: 304px;
  font-size: 0.67rem;
  border: 0.5px solid;
}

::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th {
  color: rgb(0, 0, 0);
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.row-pointer tbody tr :hover {
  cursor: pointer;
}
td {
  border-bottom: 1px solid thin #fff;
}
.orange {
  background-color: orange;
}
.v-application .uno >>> tbody tr {
  height: 76px !important;
}
.red {
  background-color: red;
}
.dos >>> tbody tr {
  height: 76px !important;
}
.blue {
  background-color: blue;
}
.purple {
  background-color: purple;
}
.grey {
  background-color: grey;
}
</style>

<script>
import axios from "../../axios-auth.js";
import router from "../../router/index.js";
import moment from "moment";

export default {
  name: "GestionTurnos",
  props: [
    "idProfesional",
    "idInstitucion",
    "listaLunes",
    "listaMartes",
    "listaMiercoles",
    "listaJueves",
    "listaViernes",
    "listaSabado",
    "listaDomingo",
    "headerL",
    "headerM",
    "headerMi",
    "headerJ",
    "headerV",
    "headerS",
    "headerD",
    "fechaMinima",
    "fechaMaxima",
    "idObraSocial",
    "nombreProfesional",
    "loading",
    "nroAfiliado",
    "initialDate",
  ],
  data: (vm) => ({
    clase: "",
    dialog: false,
    alert: false,
    showNuevoTurno: false,
    showEditarTurno: false,
    showColores: false,
    showMenu: false,
    x: 0,
    y: 0,
    itemsMenu: [],
    showNuevoCobro: false,
    valid: true,
    copiar: 0,
    name: "",
    refrescarPantalla: false,
    interval: null,
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    select: null,
    expanded: [],
    singleExpand: false,
    search: "",

    headersLength: {
      type: Number,
    },
    editedIndex: -1,
    editedItem: {
      hora: "",
      paciente: "",
    },
    defaultItem: {
      hora: "",
      paciente: "",
    },
    date: vm.toISOLocal(new Date()),
    dateFormatted: vm.formatDate(vm.toISOLocal(new Date())),
    menu: false,
    modal: false,
    menu2: false,
    prof: "",
    profs: [],
    diaSemana: null,
    on: null,
    horaTurno: "",
    itemCopia: {
      idTurno: null,
      tiempoTurno: null,
      cantIntervalos: null,
    },
    loadingTurnos: false,
  }),
  watch: {
    dialog(val) {
      val || this.close();
    },
    initialDate: {
      immediate: true,
      handler(val) {
        this.date = val;
        this.dateFormatted = moment(val).format("DD/MM/YYYY");
      },
    },
  },
  computed: {
    sinTurnos() {
      return this.date == this.fechaMinima && this.date >= this.fechaMaxima;
    },
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Turno" : "Editar Turno";
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    isLoading: {
      get() {
        return this.loading;
      },
    },
    listaTurnosLunes: {
      get() {
        return this.listaLunes;
      },
      set(value) {
        if (value != []) {
          this.$emit("buscarTurnos", this.date);
        }
      },
    },
    listaTurnosMartes: {
      get() {
        return this.listaMartes;
      },
      set(value) {
        if (value != []) {
          this.$emit("buscarTurnos", this.date);
        }
      },
    },
    listaTurnosMiercoles: {
      get() {
        return this.listaMiercoles;
      },
      set(value) {
        if (value != []) {
          this.$emit("buscarTurnos", this.date);
        }
      },
    },
    listaTurnosJueves: {
      get() {
        return this.listaJueves;
      },
      set(value) {
        if (value != []) {
          this.$emit("buscarTurnos", this.date);
        }
      },
    },
    listaTurnosViernes: {
      get() {
        return this.listaViernes;
      },
      set(value) {
        if (value != []) {
          this.$emit("buscarTurnos", this.date);
        }
      },
    },
    listaTurnosSabado: {
      get() {
        return this.listaSabado;
      },
      set(value) {
        if (value != []) {
          this.$emit("buscarTurnos", this.date);
        }
      },
    },
    listaTurnosDomingo: {
      get() {
        return this.listaDomingo;
      },
      set(value) {
        if (value != []) {
          this.$emit("buscarTurnos", this.date);
        }
      },
    },
    headerLunes: {
      get() {
        return this.headerL;
      },
      set(value) {
        if (value != []) {
          this.$emit("buscarTurnos", this.date);
        }
      },
    },
    headerMartes: {
      get() {
        return this.headerM;
      },
      set(value) {
        if (value != []) {
          this.$emit("buscarTurnos", this.date);
        }
      },
    },
    headerMiercoles: {
      get() {
        return this.headerMi;
      },
      set(value) {
        if (value != []) {
          this.$emit("buscarTurnos", this.date);
        }
      },
    },
    headerJueves: {
      get() {
        return this.headerJ;
      },
      set(value) {
        if (value != []) {
          this.$emit("buscarTurnos", this.date);
        }
      },
    },
    headerViernes: {
      get() {
        return this.headerV;
      },
      set(value) {
        if (value != []) {
          this.$emit("buscarTurnos", this.date);
        }
      },
    },
    headerSabado: {
      get() {
        return this.headerS;
      },
      set(value) {
        if (value != []) {
          this.$emit("buscarTurnos", this.date);
        }
      },
    },
    headerDomingo: {
      get() {
        return this.headerD;
      },
      set(value) {
        if (value != []) {
          this.$emit("buscarTurnos", this.date);
        }
      },
    },
  },
  created() {
    this.setToday();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    volver() {
      this.setToday();
      this.$emit("goToStep3");
    },

    setToday() {
      var hoy = new Date().getDay();
      if (hoy == 0) hoy = 6;
      else hoy -= 1;
      let dias = 1000 * 60 * 60 * 24 * hoy;

      let resta = new Date().getTime() - dias;
      this.date = this.toISOLocal(new Date(resta));
      this.dateFormatted = moment(resta).format("DD/MM/YYYY");
    },

    setClasses(item) {
      return "row-pointer";
    },
    salir() {
      router.push("/Home");
    },
    guardarTurno(item, val) {
      this.loadingTurnos = true;
      this.$swal({
        title: "Guardar Turno",
        text: "¿Desea registrar el turno?",
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          const datosTurno = {
            idInstitucion: this.idInstitucion,
            idUsuarioPaciente: this.$store.state.idUsuarioPaciente,
            fechaTurnoString: item.fechaTurnoString,
            horaInicioString: item.hora,
            duracionTurno: item.tiempoTurno,
            idObraSocial: this.idObraSocial,
            idProfesional: this.idProfesional,
            nroAfiliado: this.nroAfiliado,
          };

          let self = this;
          axios
            .post("/PortalPaciente/GuardarTurno", datosTurno)
            .then((response) => {
              if (response.data == "Reservado") {
                self.showAlert({
                  icon: "error",
                  title:
                    "Disculpe, el turno acaba de ser tomado. Reserve otro por favor",
                  vm: self,
                });
              } else {
                if (response.data && response.data != "Reservado") {
                  self
                    .$swal({
                      title:
                        "Turno reservado. Se enviará un mail a su cuenta de correo electrónico. Revise por favor su correo no deseado o spam.",
                      icon: "success",
                      type: "success",
                      showCancelButton: true,
                      background: "#ececec",
                      confirmButtonText: "Pagar Consulta",
                      cancelButtonText: "No",
                      showCloseButton: true,
                      showLoaderOnConfirm: true,
                    })
                    .then((result) => {
                      if (result.value) {
                        window.open(response.data, "_blank");
                      }
                    });
                } else {
                  self.showAlert({
                    icon: "success",
                    title:
                      "Turno reservado. Se enviará un mail a su cuenta de correo electrónico. Revise por favor su correo no deseado o spam.",
                    vm: self,
                  });
                }
                self.$emit("turnoRegistrado");
              }
              self.loadingTurnos = false;
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "El turno no pudo ser reservado",
                vm: this,
              });
              self.loadingTurnos = false;
            });
        } else {
          this.loadingTurnos = false;
        }
      });
    },
    retrocederSemana() {
      let dias = 1000 * 60 * 60 * 24 * 6;
      let resta = new Date(this.date).getTime() - dias;
      this.date = this.toISOLocal(new Date(resta));
      this.dateFormatted = moment(resta).format("DD/MM/YYYY");
      this.armarTurnos();
    },
    avanzarSemana() {
      let dias = 1000 * 60 * 60 * 24 * 8;
      let resta = new Date(this.date).getTime() + dias;
      this.date = this.toISOLocal(new Date(resta));
      this.dateFormatted = moment(resta).format("DD/MM/YYYY");
      this.armarTurnos();
    },
    definirAccion(item) {
      if (item.title === "Reservar Turno")
        this.guardarTurno(this.editedItem, 0);
    },
    rowClasses(item) {
      if (item.paciente === "Feriado" || item.paciente === "Cancelación")
        return "red";
      else {
        if (item.idTurno > 0) {
          if (item.estadoTurno === 1) {
            if (item.entreTurno === 1) {
              return "grey";
            } else {
              switch (item.cantIntervalos) {
                case 1:
                  return "blue";
                  break;
                case 2:
                  return "blue dos";
                  break;
                case 3:
                  return "blue tres";
                  break;
                case 4:
                  return "blue cuatro";
                  break;
                case 5:
                  return "blue cinco";
                  break;
                case 6:
                  return "blue seis";
                  break;
                case 7:
                  return "blue siete";
                  break;
                case 8:
                  return "blue ocho";
                  break;
              }
            }
          } else {
            if (item.estadoTurno === 2) {
              if (item.entreTurno === 1) {
                return "orange";
              } else {
                switch (item.cantIntervalos) {
                  case 1:
                    return "orange";
                    break;
                  case 2:
                    return "orange dos";
                    break;
                  case 3:
                    return "orange tres";
                    break;
                  case 4:
                    return "orange cuatro";
                    break;
                  case 5:
                    return "orange cinco";
                    break;
                  case 6:
                    return "orange seis";
                    break;
                  case 7:
                    return "orange siete";
                    break;
                  case 8:
                    return "orange ocho";
                    break;
                }
              }
            } else {
              if (item.entreTurno === 1) {
                return "purple";
              } else {
                switch (item.cantIntervalos) {
                  case 1:
                    return "purple";
                    break;
                  case 2:
                    return "purple dos";
                    break;
                  case 3:
                    return "purple tres";
                    break;
                  case 4:
                    return "purple cuatro";
                    break;
                  case 5:
                    return "purple cinco";
                    break;
                  case 6:
                    return "purple seis";
                    break;
                  case 7:
                    return "purple siete";
                    break;
                  case 8:
                    return "purple ocho";
                    break;
                }
              }
            }
          }
        } else {
          return "green";
        }
      }

      this.clase = "row-pointer";
    },
    despliegaMenu(item, row) {
      this.itemsMenu = [];

      this.itemsMenu.push({ title: "Reservar Turno", icon: "mdi-calendar" });

      this.x = event.clientX;
      this.y = event.clientY;
      this.editedItem = item;
      this.showMenu = !this.showMenu;
    },

    allowedDates: (val) => new Date(val).getDay() === 0,

    allowedDates: (val) => new Date(val).getDay() === 0,

    armarHeaders() {
      this.headerLunes = 1;
    },
    armarTurnos() {
      this.listaTurnosLunes = 1;
      this.dateFormatted = moment(this.date).format("DD/MM/YYYY");
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 3800,
      });
    },
    mostrarColores() {
      this.showColores = true;
    },
    calcularHora(hora) {
      var division = hora.split(":");
      var hoy = new Date();
      var fecha = new Date(
        hoy.getYear(),
        hoy.getMonth(),
        hoy.getDay(),
        division[0],
        parseInt(division[1] - 1)
      );
      var minutos = fecha.getMinutes().toString();
      if (minutos.length == 1) minutos = "0" + minutos;
      var horaNueva = fecha.getHours() + ":" + minutos;
      return horaNueva;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}-`;
    },
    toISOLocal(d) {
      var z = (n) => ("0" + n).slice(-2);
      var zz = (n) => ("00" + n).slice(-3);
      var off = d.getTimezoneOffset();
      var sign = off < 0 ? "+" : "-";
      off = Math.abs(off);
      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate()) +
        "T" +
        z(d.getHours()) +
        ":" +
        z(d.getMinutes()) +
        ":" +
        z(d.getSeconds()) +
        "." +
        zz(d.getMilliseconds()) +
        "Z"
      )
        .toString()
        .substr(0, 10);
    },

    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 3500,
      });
    },
  },
};
</script>
