<template>
  <div>
    <v-alert color="purple" dark dismissible outlined class="mb-0 mt-2"
      >No es necesario ingresar el nombre completo. Cualquier coincidencia
      parcial con la institución que busca se mostrará en los resultados. Si el
      profesional que está buscando trabaja de forma particular, ingrese el
      nombre del profesional.</v-alert
    >

    <v-card-text class="pt-0 mt-0">
      <v-chip class="mt-4" label color="transparent" text-color="black">
        <h3>Buscar Institución por nombre</h3>
      </v-chip>

      <v-row dense class="mt-2">
        <v-col cols="12" md="6" sm="6">
          <v-text-field
            v-model="nombre"
            prepend-icon="mdi-magnify"
            label="Nombre Institución"
            maxlength="50"
            hint="Si aprieta buscar sin introducir nada aquí, se mostrarán todas las instituciones"
            dense
            @keypress.enter="buscarInstitucion(1)"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" md="6" sm="6">
          <v-btn
            :disabled="loading"
            :loading="loading"
            color="primary"
            class="ml-8"
            @click.stop="buscarInstitucion(1)"
          >
            Buscar
            <v-icon dark right>mdi-magnify</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>

    <div class="cointainer">
      <v-row class="mx-4 justify-center">
        <div
          v-for="item in listaInstituciones"
          :key="item.idInstitucion"
          class="col-sm-4 col-md-5 card"
          style="border: 0.1px solid grey; margin: 10px"
          @click="seleccionar(item.idInstitucion)"
        >
          <div class="panel panel-success">
            <div class="panel-heading text-center">
              <h3 class="panel-title">{{ item.nombre }}</h3>
            </div>
            <div class="panel-body">
              <h5 class="mt-2 no-flickr">
                <v-icon small class="mr-1 no-flickr">mdi-map-marker</v-icon>
                <strong>Dirección:</strong> {{ item.direccion }}
                <v-btn
                  color="blue"
                  x-small
                  outlined
                  @click.stop="verDireccion(item)"
                  v-show="item.latitud != 0 && item.longitud != 0"
                  class="ml-1 no-flickr"
                >
                  <v-icon>mdi-google-maps</v-icon>
                </v-btn>
              </h5>

              <h5 class="no-flickr">
                <v-icon small class="mr-1 no-flickr">mdi-phone</v-icon>
                <strong class="no-flickr">Tel.:</strong> {{ item.telefono }}
              </h5>
              <h5 class="no-flickr">
                <v-icon small class="mr-1 no-flickr">mdi-cellphone</v-icon>
                <strong class="no-flickr">Cel.:</strong> {{ item.celular }}
              </h5>
              <h5 class="no-flickr">
                <v-icon small class="mr-1 no-flickr">mdi-email</v-icon>
                <strong class="no-flickr">Mail:</strong> {{ item.mail }}
              </h5>
              <h5 v-if="item.sitioWeb != 'Sin Datos'" class="no-flickr">
                <v-icon small class="mr-1 no-flickr">mdi-web</v-icon>
                <strong class="no-flickr">Sitio Web: </strong>
                <a class="no-flickr" @click.prevent="" :href="item.sitioWeb">{{
                  item.sitioWeb
                }}</a>
              </h5>
              <h5 v-else>
                <v-icon small class="mr-1 no-flickr">mdi-web</v-icon>
                <strong class="no-flickr">Sitio Web:</strong>
                {{ item.sitioWeb }}
              </h5>
              <!-- <div class="text-center">
                <v-btn
                  small
                  class="success mt-2"
                  @click="seleccionar(item.idInstitucion)"
                  >Seleccionar</v-btn
                >
              </div> -->
            </div>
          </div>
        </div>

        <VerDireccionMaps
          v-if="this.$store.state.vista == 0"
          :visible="showVerDireccion"
          :latitud="item.latitud"
          :longitud="item.longitud"
          @close="showVerDireccion = false"
        ></VerDireccionMaps>
        <VerDireccionMapsCel
          v-if="this.$store.state.vista == 1"
          :visible="showVerDireccion"
          :latitud="item.latitud"
          :longitud="item.longitud"
          @close="showVerDireccion = false"
        ></VerDireccionMapsCel>
      </v-row>
    </div>

    <v-row class="mx-4">
      <div class="text-center">
        <v-pagination
          v-if="listaInstituciones.length > 0"
          v-model="page"
          @next="buscarInstitucion(page)"
          @previous="buscarInstitucion(page)"
          @input="buscarInstitucion(page)"
          :length="totalPages"
          color="cyan"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
        ></v-pagination>
      </div>
    </v-row>
    <v-divider></v-divider>
  </div>
</template>
<script>
import axios from "../../axios-auth.js";
import VerDireccionMaps from "../Turnos/VerDireccionMaps.vue";
import VerDireccionMapsCel from "../Turnos/VerDireccionMapsCel.vue";
export default {
  name: "BuscarInstFiltros",
  data: (vm) => ({
    page: 1,
    valid: false,
    lazy: false,
    loading: false,
    nombre: "",
    listaInstituciones: [],
    totalPages: 1,
    item: {
      latitud: 0,
      longitud: 0,
    },
    showVerDireccion: false,
  }),
  methods: {
    buscarInstitucion(page) {
      this.loading = true;
      const institucionData = {
        nombre: this.nombre,
      };
      let self = this;
      axios
        .get("/PortalPaciente/GetInstitucionNombreDispWeb?", {
          params: {
            nombre: institucionData.nombre,
            numeroDePagina: page,
          },
        })
        .then((response) => {
          if (response.data != null && response.data != "") {
            self.listaInstituciones = response.data.listaInstituciones;
            self.totalPages = response.data.cantPaginas;
          } else {
            self.listaInstituciones = [];
            self.showAlert({
              icon: "error",
              title:
                "No hay instituciones que coincidan con los filtros de búsqueda ingresados.",
              vm: self,
            });
          }
          self.loading = false;
        })

        .catch((error) => {
          self.loading = false;
        });
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 2500,
      });
    },
    seleccionar(key) {
      //console.log(key)
      this.$emit("institucionSeleccionada", key);
    },
    verDireccion(item) {
      this.item = item;
      this.showVerDireccion = true;
    },
  },
  components: {
    VerDireccionMaps,
    VerDireccionMapsCel,
  },
};
</script>
<style scoped>
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border: 0px !important;
  cursor: pointer;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  background-color: rgb(210, 223, 228);
}
</style>
