<template>
  <v-dialog v-model="show" transition="dialog-bottom-transition">
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="show = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Video</v-toolbar-title>
    </v-toolbar>

    <iframe
      id="player"
      width="580"
      height="450"
      class="yvideo"
      ref="yvideo"
      :src="src"
      frameborder="0"
      allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
    ></iframe>
    <!-- <youtube id="player" player-width="950" player-height="440" :video-id="src"></youtube> -->
    <v-card>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="show = false" color="error">Salir</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data: (vm) => ({
    src: null,
  }),
  props: ["visible", "link"],
  computed: {
    show: {
      get() {
        this.iniciarVideo();
        return this.visible;
      },
      set(value) {
        if (!value) {
          let yvideo = this.$refs['yvideo']
          yvideo.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*')
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    iniciarVideo() {
      this.src = this.link + "?enablejsapi=1";
    },
  },
};
</script>