<template>
  <div>
    <v-card-text class="pt-0 mt-0">
      <v-chip class="mt-4" label color="transparent" text-color="black">
        <h3>
          Seleccionar
          {{ this.$store.state.esEmpresa ? "Sede" : "Obra Social" }}
        </h3>
      </v-chip>

      <v-row dense class="mt-4" v-if="obrasSociales.length > 0">
        <v-col cols="12" md="3" sm="4">
          <v-select
            :items="obrasSociales"
            item-text="nombre"
            item-value="idObraSocial"
            prepend-icon="mdi-card-account-details"
            v-model="obraSocial"
            :label="resolveLabel"
            dense
          ></v-select>
        </v-col>
        <v-col cols="12" sm="4" md="4" v-if="idInstitucion === 195">
          <v-text-field
            v-model="nroAfiliado"
            prepend-icon="mdi-account"
            label="Nro. Afiliado"
            maxlength="100"
            :rules="requiredRules"
            dense
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row dense class="mt-4" v-if="obrasSociales.length == 0">
        <v-col cols="12" md="3" sm="4">
          <v-alert color="blue" dark outlined class="mb-0 mt-2"
            >La institución no posee Obras Sociales configuradas.</v-alert
          >
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="4" md="2">
          <v-btn
            :disabled="
              obrasSociales.length === 0 ||
              (idInstitucion === 195 && !nroAfiliado)
            "
            small
            class="success mt-2"
            @click.stop="seleccionar"
            >Continuar</v-btn
          >
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>
<style scoped>
.center {
  margin-left: auto;
  margin-right: auto;
  display: block;
}
</style>
<script>
export default {
  props: ["obrasSociales", "idInstitucion"],
  watch: {
    obrasSociales: function (val) {
      if (this.obrasSociales.length > 0)
        this.obraSocial = this.obrasSociales[0].idObraSocial;
    },
  },
  data: (vm) => ({
    obraSocial: null,
    nroAfiliado: "",
  }),
  computed: {
    resolveLabel() {
      return this.$store.state.esEmpresa ? "Sede" : "Obras Sociales Vigentes";
    },
  },
  methods: {
    seleccionar() {
      this.$emit("obraSocialSeleccionada", {
        obraSocial: this.obraSocial,
        nroAfiliado: this.nroAfiliado,
      });
    },
  },
  mounted() {
    if (this.obrasSociales.length > 0)
      this.obraSocial = this.obrasSociales[0].idObraSocial;
  },
};
</script>
