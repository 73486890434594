<template>
  <div class="pa-4" style="background: #a1a1a1">
    <h2 style="color: white" class="mx-auto my-4 py-0">
      Bienvenido {{ usuario }}!
    </h2>

    <v-card class="mb-2" color="blue" dark tile>
      <v-card-title>
        <v-icon large left>mdi-star</v-icon>
        <span class="title font-weight-light">Acceso Rápido</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="3" sm="12">
            <v-row align="center" justify="center">
              <v-card
                dark
                color="#1574db"
                height="160"
                width="220"
                @click="nuevoTurno(0)"
              >
                <v-card-title class="headline layout justify-center">
                  <v-col cols="12" class="headline layout justify-center">
                    <h3>
                      <v-icon x-large>mdi-tablet</v-icon>
                    </h3>
                  </v-col>

                  <h5>Nuevo Turno</h5>
                  <br />
                  <h5>Vista PC</h5>
                </v-card-title>
              </v-card>
            </v-row>
          </v-col>
          <v-col cols="12" md="3" sm="12">
            <v-row align="center" justify="center">
              <v-card
                dark
                color="#1574db"
                height="160"
                width="220"
                @click="nuevoTurno(1)"
              >
                <v-card-title class="headline layout justify-center">
                  <v-col cols="12" class="headline layout justify-center">
                    <h3>
                      <v-icon x-large>mdi-cellphone</v-icon>
                    </h3>
                  </v-col>
                  <h5>Nuevo Turno</h5>
                  <br />
                  <h5>Vista Celular</h5>
                </v-card-title>
              </v-card>
            </v-row>
          </v-col>

          <v-col cols="12" md="3" sm="12">
            <v-row align="center" justify="center">
              <v-card
                dark
                color="#1574db"
                height="160"
                width="220"
                to="/MisTurnos"
              >
                <v-card-title class="headline layout justify-center">
                  <v-col cols="12" class="headline layout justify-center">
                    <h3>
                      <v-icon x-large>mdi-calendar</v-icon>
                    </h3>
                  </v-col>

                  <h5>Mis Turnos</h5>
                </v-card-title>
              </v-card>
            </v-row>
          </v-col>

          <v-col cols="12" md="3" sm="12">
            <v-row align="center" justify="center">
              <v-card
                dark
                color="#1574db"
                height="160"
                width="220"
                to="/Informes"
              >
                <v-card-title class="headline layout justify-center">
                  <v-col cols="12" class="headline layout justify-center">
                    <h3>
                      <v-icon x-large>mdi-hospital</v-icon>
                    </h3>
                  </v-col>

                  <h5>Informes Médicos</h5>
                </v-card-title>
              </v-card>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mx-auto" color="orange" dark tile>
      <v-card-title>
        <v-icon large left>mdi-youtube-tv</v-icon>
        <span class="title font-weight-light">Videos Tutoriales</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4" sm="12">
            <v-row align="center" justify="center">
              <v-card dark color="#db8315" width="300" to="/TutorialesTurnos">
                <v-card-title class="headline layout justify-center">
                  <v-col cols="12" class="headline layout justify-center">
                    <h3>
                      <v-icon x-large>mdi-calendar-multiple</v-icon>
                    </h3>
                  </v-col>
                  <h3>Turnos</h3>
                </v-card-title>
              </v-card>
            </v-row>
          </v-col>
          <v-col cols="12" md="4" sm="12">
            <v-row align="center" justify="center">
              <v-card dark color="#db8315" width="300" to="/TutorialesConfig">
                <v-card-title class="headline layout justify-center">
                  <v-col cols="12" class="headline layout justify-center">
                    <h3>
                      <v-icon x-large>mdi-hospital</v-icon>
                    </h3>
                  </v-col>
                  <h3>Informes</h3>
                </v-card-title>
              </v-card>
            </v-row>
          </v-col>

          <v-col cols="12" md="4" sm="12">
            <v-row align="center" justify="center">
              <v-card dark color="#db8315" width="300" to="/TutorialesCuenta">
                <v-card-title class="headline layout justify-center">
                  <v-col cols="12" class="headline layout justify-center">
                    <h3>
                      <v-icon x-large>mdi-key-variant</v-icon>
                    </h3>
                  </v-col>
                  <h3>Cuenta</h3>
                </v-card-title>
              </v-card>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<style scoped>
.multi-line {
  white-space: pre-line;
}
</style>
<script>
import router from "../router/index.js";

export default {
  data: (vm) => ({
    usuario: null,
    titulo: "",
    modulo: "",
  }),
  created() {
    this.usuario = this.$store.state.nombreUsuarioPaciente.toString().trim();
  },
  mounted() {
    if (
      this.$store.state.nombreUsuarioPaciente == "" ||
      this.$store.state.nombreUsuarioPaciente == null
    ) {
      this.$store.dispatch("logout");
    }
  },
  methods: {
    nuevoTurno(tipoVista) {
      if (tipoVista == 0) this.$store.dispatch("setVista", 0);
      else this.$store.dispatch("setVista", 1);

      router.push("/BuscarInstitucion");
    },
  },
};
</script>
