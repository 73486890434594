<template>
  <div class="container">
    <v-card class="mb-2" tile>
    <v-card-title>
        <span class="title font-weight-light">Informes</span>
      </v-card-title>
      <v-card-text>
        <v-row dense>
            <v-col cols="12" sm="7" md="4">
              <v-text-field
                v-model="codInforme"
                prepend-icon="mdi-account"
                label="Ingrese el código del informe"
                hint="El código consta de 10 dígitos y debe ser provisto por la institución"
                maxlength="80"
                :rules="requiredRules"
                dense
                required
              ></v-text-field>
            </v-col>
          </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
         <v-btn 
         color="success" 
         @click.stop="buscarInforme" 
         :loading="loading"
         :disabled="checkLen || loading">Ver Informe</v-btn>
         
      </v-card-actions>
      
      <VerInforme v-if="editedItem!=null" :visible="showVerInforme" :informe="editedItem" @close="showVerInforme=false"></VerInforme>
      <VerImagenes :visible="showVerImagenes" :images="listaImagenes" @close="showVerImagenes=false"></VerImagenes>
    </v-card>
  </div>
</template>
<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
<script>
import axios from "../../axios-auth.js";
import VerInforme from "../HistClin/VerInforme.vue"
import VerImagenes from "../HistClin/VerImagenes.vue"

export default {
  data: (vm) => ({
    loading: false,
    showVerInforme:false,
    codInforme: '',
    editedItem: null,
    requiredRules: [(v) => !!v || "Dato obligatorio"],
    showVerImagenes: false,
    listaImagenes: [],
  }),
  
  methods: {
    seleccionVer(item)
    {
        if(item.varios==false)
        {
          this.verInforme(item) 
        }
        else 
        {
          this.verImagenes(item)
        }
    },
    verInforme(item){
        this.editedItem = item;
        this.showVerInforme= true;
    },
    verImagenes(flag) {
        this.listaImagenes = flag.imagenesInformes
        this.showVerImagenes = true;
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: alertInfo.timer
      });
    },
           
    buscarInforme(){
       this.loading = true
       const filtrosData = {
        idUsuarioPaciente: this.$store.state.idUsuarioPaciente,
        codInforme: this.codInforme
                
      };
      
      let self= this;
       axios.get('/PortalPaciente/GetInforme?', {
        params: { 
         idUsuarioPaciente: filtrosData.idUsuarioPaciente,
         codInforme: filtrosData.codInforme
        }
                
      }).then(response => {
      if (response.data!=null && response.data!='')
      {
        self.loading = false
        self.seleccionVer(response.data)        
      }
      else
      {
        self.showAlert({
                icon: "error",
                title: "No existe un informe con ese código asociado a su número de documento.",
                vm: self,
                timer: 3500
              });
        self.loading = false
      }
      }).catch(function(error){       
      })
    }
  },
  components:{
        VerInforme,
        VerImagenes 
  },
  computed:{
    checkLen(){
      if(this.codInforme.length<10) return true
      else return false
    }
  },
};
</script>