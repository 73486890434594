<template>
  <div class="container">
    <v-card class="pb-3">
      <v-toolbar dark color="primary">
        <v-toolbar-title>Mis Turnos</v-toolbar-title>
      </v-toolbar>


        <v-card-text class="pa-0">
          <v-row>
            <v-col cols="12">
               <v-chip label color="transparent" text-color="black"> 
               <h3>Turnos Vigentes</h3>
              </v-chip>
            </v-col>
          </v-row>
         
        </v-card-text>
 

      <v-data-table
        :headers="headers"
        :items="this.listaTurnosVigentes"
        class="elevation-1"
        locale="es-ar"
        item-key="idTurno"
        :items-per-page="10"
        ref="myTable"
        :disable-sort="true"
        :footer-props="{
          itemsPerPageText: 'Filas por página'
        }"
        v-if="listaTurnosVigentes.length>0"
        >
         <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="orange"
                class="mr-3"
                v-on="on"
                v-if="item.estadoTurnoString == 'RESERVADO'"
                v-bind="attrs"
                @click.stop="anunciarse(item)"
              >mdi-bullhorn</v-icon>
            </template>
            <span>Anunciar llegada a consulta</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                v-on="on"
                color="red"
                v-bind="attrs"
                @click="borrarTurno(item)"
              >mdi-delete</v-icon>
            </template>
            <span>Cancelar Turno</span>
          </v-tooltip>
        </template>   
      </v-data-table>
       <v-alert
      color="blue"
      dark
      outlined
      v-if="listaTurnosVigentes.length==0"
      class="mb-0 mt-0 mx-3"
    >No hay turnos vigentes gestionados a través del Portal del Paciente.</v-alert>

       <v-card-text class="pa-0">
          <v-row>
            <v-col cols="12">
               <v-chip label color="transparent" text-color="black"> 
               <h3>Turnos Pasados</h3>
              </v-chip>
            </v-col>
          </v-row>
         
        </v-card-text>

         <v-data-table
        :headers="headersPasados"
        :items="this.listaTurnosPasados"
        class="elevation-1"
        locale="es-ar"
        item-key="idTurno"
        :items-per-page="10"
        ref="myTable"
        :disable-sort="true"
        v-if="listaTurnosPasados.length>0"
        :footer-props="{
          itemsPerPageText: 'Filas por página'
        }"
        >
                   
      </v-data-table>
       <v-alert
      color="blue"
      dark
      outlined
      v-if="listaTurnosPasados.length==0"
      class="mb-3 mt-0 mx-3"
    >No hay turnos pasados.</v-alert>
    </v-card>
  </div>
</template>
<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
</style>
<script>
import axios from "../../axios-auth.js";
import router from "../../router/index.js";

export default {
  data: vm => ({
     headers: [
      { text: "Dia", value: "diaSemana" },
      { text: "Fecha", value: "fechaTurnoString" },
      { text: "Hora", value: "horaInicioString", sortable:false},   
      { text: "Profesional", value: "nombreProfesional" },
      { text: "Estado", value: "estadoTurnoString" },
      { text: "Acciones", value: "actions", sortable: false }
    ],
    headersPasados: [
      { text: "Dia", value: "diaSemana" },
      { text: "Fecha", value: "fechaTurnoString" },
      { text: "Hora", value: "horaInicioString", sortable:false},   
      { text: "Profesional", value: "nombreProfesional" },
      { text: "Estado", value: "estadoTurnoString" }  
    ],
    listaTurnosVigentes: [],
    listaTurnosPasados: []
  }),
  methods: {
    salir() {
      router.push({ path: "/Home" });
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: alertInfo.timer
      });
    },
    borrarTurno(item){
        this.$swal({
        title: "Cancelar Turno",
        text: "¿Desea cancelar el turno?",
        icon: "success",
        background: "#ececec",
        showClass: {
          popup: "animate__animated animate__fadeInDown"
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp"
        },
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true
      }).then(result => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          const idTurno = item.idTurno;
          let self = this;
          axios
            .get("/Turnos/BorrarTurno?", {
              params: {
                idTurno: idTurno
              }
            })
            .then(response => {
              self.showAlert({
                icon: "success",
                title: "Turno cancelado exitosamente",
                vm: self,
                timer: 2000
              });
              setTimeout(self.buscarTurnos(), 1700);
            })
            .catch(error => {
              self.showAlert({
                icon: "error",
                title: "El turno no pudo ser cancelado. Intente nuevamente mas tarde",
                vm: this,
                timer: 2800
              });
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
   
    buscarTurnos(){
      
        const filtrosData = {
        idUsuarioPaciente: this.$store.state.idUsuarioPaciente,
        
      };
      
      let self= this;
       axios.get('/Turnos/BuscarTurnosVigentes?', {
        params: { 
        idUsuarioPaciente: filtrosData.idUsuarioPaciente
        }
                
      }).then(function(response) {
      
      if(response.data!='' && response.data!=null)
      {
        self.listaTurnosVigentes = response.data
      }
      else
      {
        self.listaTurnosVigentes = []
      }
      }).catch(function(error){
        
        
        
      })

      axios.get('/Turnos/BuscarTurnosPasados?', {
        params: { 
        idUsuarioPaciente: filtrosData.idUsuarioPaciente
        }
                
      }).then(function(response) {
      
      if(response.data!='' && response.data!=null)
      {
        self.listaTurnosPasados = response.data
      } 
      else
      {
        self.listaTurnosPasados = []
      }  
      }).catch(function(error){
        
        
        
      })
    },
  anunciarse(item){
     let self = this;
     this.$swal({
                title: "Anunciar llegada a consulta",
                text: "¿Desea indicar que ha llegado a la consulta?",
                icon: "success",
                type: "success",
                showCancelButton: true,
                background: "#ececec",
                confirmButtonText: "Si",
                cancelButtonText: "No",
                showCloseButton: true,
                showLoaderOnConfirm: true,
              }).then((result) => {
               
                  if(result.value == true)
                  {
                  axios.get('/Turnos/Espera?',{
                    params: {
                      idTurno: item.idTurno
                    }
                  }).then(response => {
                     self.showAlert({
                     icon: "success",
                     title: "El estado del turno ha cambiado a en espera, indicando que ha llegado a la consulta.",
                     vm: self,
                     timer: 3500
                  });
                   setTimeout(self.buscarTurnos(), 1700);
                  })

                  }
              })
  }        
  },

  created() {
  this.buscarTurnos();    
  },

}
</script>