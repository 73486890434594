<template>
  <div class="container">
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>
            Registrar Usuario
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-alert color="indigo" dark dismissible outlined
            >Por favor registre el usuario con los datos del paciente real que
            va a tomar el turno</v-alert
          >
          <v-alert color="purple" dark dismissible outlined>{{
            resolveRequiredFields
          }}</v-alert>
          <v-row dense>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="paciente.mail"
                prepend-icon="mdi-email"
                label="E-mail *"
                :rules="emailRules"
                @blur="paciente.mail = paciente.mail.toString().trim()"
                dense
                maxlength="80"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="mail2"
                prepend-icon="mdi-email"
                label="Confirme su E-mail *"
                @blur="
                  $v.mail2.$touch();
                  mail2 = mail2.toString().trim();
                "
                :rules="emailRules"
                dense
                maxlength="80"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="paciente.apellidos"
                prepend-icon="mdi-account"
                label="Apellidos *"
                maxlength="50"
                :rules="requiredRules"
                @blur="upper"
                dense
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="paciente.nombres"
                prepend-icon="mdi-account"
                label="Nombres *"
                maxlength="50"
                :rules="requiredRules"
                @blur="upperNombres"
                dense
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model.number="paciente.dni"
                type="number"
                prepend-icon="mdi-account"
                label="D.N.I *"
                dense
                required
                @keypress="onlyNumber"
                onpaste="return false;"
                :rules="requiredRules"
                maxlength="9"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="paciente.pass"
                prepend-icon="mdi-lock"
                :rules="passwordRules"
                label="Contraseña *"
                type="Password"
                required
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="paciente.direccion"
                prepend-icon="mdi-map-marker"
                label="Direccion"
                maxlength="80"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="paciente.localidad"
                prepend-icon="mdi-map-marker"
                label="Localidad"
                maxlength="80"
                dense
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="3"
              md="3"
              v-if="this.$store.state.idInstitucion === 195"
            >
              <v-text-field
                v-model="paciente.celular"
                prepend-icon="mdi-cellphone"
                label="Celular *"
                hint="Ingrese el celular con la característica, sin 0, sin 15 y sin guiones. Ej: 1135036549"
                maxlength="50"
                dense
                :rules="requiredCelularRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-text-field
                v-model="paciente.fechaNac"
                :value="paciente.fechaNac"
                label="Fecha de Nacimiento"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-select
                :items="sexo"
                v-model="paciente.sexo"
                item-text="paciente.sexo"
                item-value="paciente.sexo"
                label="Sexo"
                dense
                prepend-icon="mdi-account"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              sm="3"
              md="3"
              v-if="this.$store.state.idInstitucion !== 195"
            >
              <v-text-field
                v-model="paciente.celular"
                prepend-icon="mdi-cellphone"
                label="Celular"
                hint="Ingrese el celular con la característica, sin 0, sin 15 y sin guiones. Ej: 1135036549"
                maxlength="50"
                dense
                :rules="celularRules"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="3"
              md="3"
              v-if="this.$store.state.idInstitucion !== 195"
            >
              <v-text-field
                v-model="paciente.telefono"
                prepend-icon="mdi-phone"
                label="Telefono"
                maxlength="50"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pa-0">
              <ul>
                <li
                  style="color:red; font-size:13px;margin-bottom:4px"
                  v-show="!$v.mail2.sameAs"
                >
                  Los e-mail no coinciden
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :disabled="!valid || $v.$invalid || isLoading"
            :loading="isLoading"
            @click.stop="registrarme"
            >Registrarme</v-btn
          >

          <v-btn color="error" @click.stop="salir">Volver</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import axios from "../axios-auth.js";
import router from "../router/index.js";
import { sameAs } from "vuelidate/lib/validators";
export default {
  data: (vm) => ({
    horaTurno: null,
    paciente: {
      dni: null,
      apellidos: null,
      nombres: null,
      fechaNac: null,
      sexo: null,
      direccion: null,
      localidad: null,
      mail: null,
      telefono: null,
      celular: null,
      pass: null,
    },
    mail2: null,
    sexo: ["FEMENINO", "MASCULINO", "OTRO"],
    valid: false,
    lazy: false,
    requiredRules: [(v) => !!v || "Dato obligatorio"],
    requiredCelularRules: [
      (v) => !v || v.length == 10 || "Debe tener 10 caracteres",
      (v) => !!v || "Dato obligatorio",
    ],
    emailRules: [
      (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "E-mail inválido",
      (v) => !!v || "Dato obligatorio",
    ],
    passwordRules: [
      (v) => (v && v.length > 5) || "Mínimo 6 caracteres",
      (v) => !!v || "Dato obligatorio",
    ],
    celularRules: [(v) => !v || v.length == 10 || "Debe tener 10 caracteres"],
    isLoading: false
  }),
  computed: {
    resolveRequiredFields() {
      return this.$store.state.idInstitucion !== 195
        ? `Los datos obligatorios son el documento, el e-mail, el apellido, el
            nombre y la contraseña, pero recomendamos cargar la mayor cantidad
            de datos posibles`
        : `Los datos obligatorios son el documento, el e-mail, el apellido, el
            nombre, la contraseña y el celular, pero recomendamos cargar la mayor cantidad
            de datos posibles`;
    },
  },
  methods: {
    validarMail() {
      this.paciente.mail2 = this.paciente.mail2.toString().trim();
    },
    registrarme() {
      this.$swal({
        title: "Registrar Usuario",
        text: "¿Desea registrar la cuenta de usuario?",
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          const datosUsuario = {
            nrodocumento: this.paciente.dni,
            apellidos: this.paciente.apellidos,
            nombres: this.paciente.nombres,
            fechanacimiento: this.paciente.fechaNac,
            sexo: this.paciente.sexo,
            direccion: this.paciente.direccion,
            localidad: this.paciente.localidad,
            celular: this.paciente.celular,
            telefono: this.paciente.telefono,
            email: this.paciente.mail,
            pass: this.paciente.pass,
            urlInstitucion: this.$store.state.urlInstitucion,
          };

          let self = this;
          this.isLoading = true;
          axios
            .post("/Institucional/RegistrarUsuario", datosUsuario)
            .then((response) => {
              if (response.data == "dni") {
                self.showAlert({
                  icon: "error",
                  title:
                    "El D.N.I. ingresado ya se encuentra registrado como usuario.",
                  vm: self,
                });
              } else {
                self.showAlert({
                  icon: "success",
                  title:
                    "Usuario Registrado. Se envió un mail a la casilla de correo registrada para activar la cuenta. Revise por favor su correo no deseado o spam.",
                  vm: self,
                });
                if (self.$store.state.idInstitucion) {
                  router.push({
                    path: `/portal/${self.$store.state.urlInstitucion}`,
                  });
                } else {
                  router.push("/");
                }
              }
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "El usuario no pudo ser registrado",
                vm: this,
              });
            })
            .finally(() => {
              this.isLoading = false;
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },

    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    upper() {
      if (this.paciente.apellidos != null)
        this.paciente.apellidos = this.paciente.apellidos
          .toString()
          .toUpperCase();
    },
    upperNombres() {
      if (this.paciente.nombres != null)
        this.paciente.nombres = this.paciente.nombres.toString().toUpperCase();
    },

    vaciarModels() {
      this.paciente.apellidos = "";
      this.paciente.nombres = "";
      this.paciente.direccion = "";
      this.paciente.localidad = "";
      this.paciente.celular = "";
      this.paciente.telefono = "";
      this.paciente.mail = "";
      this.mail2 = "";
      this.paciente.fechaNac = null;
      this.paciente.sexo = null;
      this.paciente.dni = "";
      this.paciente.pass = "";
    },

    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 4200,
      });
    },

    salir() {
      if (this.$store.state.idInstitucion) {
        router.push({ path: `/portal/${this.$store.state.urlInstitucion}` });
      } else {
        router.push("/");
      }
    },
  },
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  created() {
    this.vaciarModels();
  },
  validations: {
    mail2: {
      //sameAs: sameAs('password')
      sameAs: sameAs((vm) => {
        return vm.paciente.mail;
      }),
    },
  },
};
</script>
