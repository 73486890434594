<template>
  <v-dialog v-model="show" persistent max-width="600px" transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Dirección en el mapa</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <img :src="src" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: (vm) => ({
    src: null
  }),
  props: ["visible", "latitud","longitud"],
  computed: {
      show: {
      get() {
        if (this.visible)
        {
          this.generarUrl()
        }
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.vaciarModels()
          this.$emit("close");
        }
      }
    },
  },
  methods: {
    generarUrl(){
      this.src = 'https://maps.googleapis.com/maps/api/staticmap?center='+this.latitud+','+this.longitud+'&zoom=16&size=280x300&markers=icon:https://i.ibb.co/PZyJWdP/marker.png|'+this.latitud+','+this.longitud+'&key=AIzaSyDTvuisfH7eyVRBBYrZYTdaGhlOVb4LP9k'
      },
      vaciarModels(){
        this.src = null
      }
  }
};
</script>